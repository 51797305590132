@media (max-width: 767px) {

    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

:root {
    --top-menu-height: 4em;
}


.top-menu {
    height: var(--top-menu-height);
}

.grid {
    width: 100%;
    display: grid;
    grid: var(--top-menu-height) / 1fr;
    grid-template-areas: "menu"
        "maincontent";
    /* min-height: 100vh; */
}

.ui.grid {
    margin-bottom: 10px !important;
}

input.search {
    padding: 10px;
    border: 1px solid #d6d3d3;
    width: 100%;
}

.rdt_Table {
    border: 1px solid rgba(0, 0, 0, .12);
}

.rdt_TableHeader {
    display: none !important;
}

.menu {
    grid-area: menu;
}

.main-content {
    grid-area: maincontent;
    background-color: #f9fafb;
}

.parent {
    display: inline-flex;
}

.side {
    transition: width 0.3s;
    width: 14em !important;
    overflow: visible;
    padding-top: var(--top-menu-height);
    z-index: 99;
}

.small-side {
    width: 4em !important;
    flex-basis: 4em;
}

.parent>.content {
    position: absolute;
    right: 0;
    transition: all 0.3s;
    padding: 10px;
    width: calc(100% - 14em);
    padding-bottom: 5%;
}

.ui.card>.content,
.ui.cards>.card>.content {

    position: static !important;
    width: auto !important;
}

.ui.card>.image>img,
.ui.cards>.card>.image>img {
    width: auto !important;
    margin: 0px auto !important;
}

.small-content {
    padding: 10px;
    width: calc(100% - 4em);
}

.logo-space-menu-item {
    width: 14em;
}

.display-inline {
    display: inline-flex;
    align-items: center;
}

.logo-space {
    font-family: "Roboto Light", serif;
    font-size: 24px;
    margin: 0 auto;

}

.logo-space img,
.display-inline img {
    margin-right: 8px;
}

.no-border::before {
    display: none;
}

.top-menu i.icon {
    margin: 0 !important;
}


.drop-left-padding {
    padding-left: 0 !important;
}

.label-on-corner {
    top: 0.8em !important;
    left: 78% !important;
}

.footer-label {
    width: 100%;
    line-height: 3;
    text-align: center;
}

.top-menu i.dropdown {
    line-height: 0em !important;
    top: 8px !important;
}

.ui.modal>.content {
    position: static !important;
}

.modal {
    position: static !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
    opacity: 1 !important;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
    opacity: 1 !important;
}

/* text editor */

.ql-container.ql-snow {
    height: 150px;
}

.service-plan {
    font-weight: 700 !important;
}

.service-plan .count {
    border: 1px solid #999999;
    margin-left: 5px;
    padding: 1px 2px;
    font-size: 12px;
    border-radius: 2px;
}

.ui.dropdown.sidebar-dropdown {
    display: flex;
    align-items: center;
}

/* pagination */

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.ui.modal {
    height: auto;
}

.thumbnails {
    padding: 24px;
    border: 1px solid rgba(34, 36, 38, 0.15);
}

.thumbnails label {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}
.thumbnails .instruction-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
     color: rgba(0, 0, 0, 0.6);
     margin-bottom: 32px;
}

.template-thum {
    flex-grow: unset;
    margin-bottom: 25px;
}

.template-thum .thum-preview {
    width: 348px;
    height: 232px;
    position: relative;
    box-shadow: 0px 6px 10px 0px #ebebeb;
}

.template-thum .thum-preview:hover .btn-overlay {
    display: flex;
}

.template-thum .thum-preview .btn-overlay {
    display: none;
    top: 0;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .70);
    flex-direction: column;
}

.template-thum .thum-preview .btn-overlay.no-preview {
    background-color: #ffffff;
}

.template-thum .thum-preview>img {
    max-width: 100%;
    width: 348px;
    height: 232px;
}



.template-thum .thum-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    margin-top: 12px;
    text-transform: capitalize;
}



.template-thum .thum-preview .ui.button {
    border-radius: 25px;
    display: flex;
    align-items: center;
}

.template-thum .thum-preview .ui.negative.button {
    background-color: #FF4170;
}

.template-thum .thum-preview .ui.negative.button:hover {}

.template-thum .thum-preview .ui.positive.button {
    background-color: #5141E7;
}

.template-thum .thum-preview .ui.positive.button:hover {}

.table-min-height .rdt_TableBody{
min-height: 300px ;
height: calc(100vh - 200px);
overflow-y: auto;
}

.sidebar-dropdown .dropdown.icon{
    margin-top: 7px !important;
}